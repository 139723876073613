import React from 'react';

export default function CloseIcongc({ color }) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 6 6 18M6 6l12 12"
        stroke={color ? color : '#000'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
