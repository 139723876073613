import React from 'react';

export default function ReadIcongc() {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)" stroke="#F4F5F7" strokeWidth={1.25} strokeLinecap="round" strokeLinejoin="round">
        <path d="M16.875 3.75H3.125a.625.625 0 0 0-.625.625v11.25c0 .345.28.625.625.625h13.75c.345 0 .625-.28.625-.625V4.375a.625.625 0 0 0-.625-.625ZM5.938 7.5h8.125M5.938 10h8.125M5.938 12.5h8.125" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
