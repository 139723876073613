import React from 'react';

export default function WatchIcon({ color }) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 22h15a1.5 1.5 0 0 0 1.5-1.5V10a1.5 1.5 0 0 0-1.5-1.5h-15A1.5 1.5 0 0 0 7 10v10.5A1.5 1.5 0 0 0 8.5 22ZM19 25h-6"
        stroke={color ? color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m19 15.25-4.5-3v6l4.5-3Z"
        stroke={color ? color : '#fff'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
