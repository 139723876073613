import { navigate } from '@reach/router';
import { graphql, Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import BodyClassName from 'react-body-classname';

import renderHtml from 'htmr';
import { decode } from 'html-entities';
import { useFetch } from '../../hooks';
import SocialShare from '../../components/Shop/SocialShare';
import DownChain from '../../images/svg-gcbooks/down-chain';
import WatchIcon from '../../images/svg-gcbooks/watch-icon';
import { AppContext } from '../../apollo/wrap-root-element';
import Backdrop from '../../components/accessories/backdrop';
import BookIconWhite from '../../images/svg-gcbooks/book-icon';
import NextIconWhite from '../../images/svg-gcbooks/next-icon';
import PrevIconWhite from '../../images/svg-gcbooks/prev-icon';
import ShareMenu from '../../components/accessories/shareMenu';
import CloseIcongc from '../../images/svg-gcbooks/close-icongc';
import ShareIconWhite from '../../images/svg-gcbooks/share-icon';
import ReadPageContent from '../../components/Read/ReadPageContent';
import ChainDisabled from '../../images/svg-gcbooks/chain-disabled';
import CollapseButton from '../../images/svg-gcbooks/collapse-button';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import NextArrow from '../../images/svg-gcbooks/next-arrow';
import PlayIcongc from '../../images/svg-gcbooks/playIcongc';
import { getSliceNumber } from '../../utils/watch';
import GCBook from '../../images/svg-gcbooks/GCBook.png';
import UpLeftArrow from '../../images/svg-gcbooks/arrow-up-right.svg';

import { COLOR_GRAY, FEATURED_SCROLLID, FOOTER_VIDEO_SCROLLID, VIDEO_SCROLLID } from '../../utils/constants';
import ReadIcongc from '../../images/svg-gcbooks/read-icongc';
import ListengcIcon from '../../images/svg-gcbooks/listengcIcon';

const isListEmpty = (arr) => {
  if (Array.isArray(arr) && !!arr?.length) {
    return false;
  }

  return true;
};

export default function BookChapterPageTemplate({ data }) {
  const {
    relatedReads,
    relatedListens,
    featuredVideoThumbnailImage,
    footerVideoThumbnailImage,
    chapterTitle,
    furtherReadings,
    footnotes,
    footerVideo,
    discussionQuestions,
    featuredVideo,
    displayPriority,
    timelineLink,
    bookText,
    relatedEpisodes,
    featuredVideoDuration,
    shortDescription,
    additionalVideos,
  } = data.allPrismicBookChapter.nodes[0]?.data;
  const sliceData = data?.allPrismicBookChapter?.nodes[0]?.data?.body;

  const title = data?.allPrismicBookChapter?.nodes[0]?.data?.chapterTitle?.text;
  const uid = data?.allPrismicBookChapter?.nodes[0]?.uid;
  const furtherReadingList = furtherReadings.map((fReading) => fReading?.furtherReading?.text).filter((e) => e);
  const footNotesList = footnotes.map((elem) => elem?.footnote?.text).filter((e) => e);
  const allChapters = data?.chapters?.nodes;
  const bookTitle = allChapters[0]?.data?.chapterOfBook?.document?.data?.title?.text;
  const featuredVideoThumbnailImageUrl = featuredVideoThumbnailImage?.fluid?.src;
  const footerVideoThumbnailImageUrl = footerVideoThumbnailImage?.fluid?.src;
  const chapterText = bookText?.document?.data?.chapterText?.text;

  const [contractContent, setContractContent] = useState(true);
  const [showReadModal, setShowReadModal] = useState(false);
  const [showShareMenu, setShowShareMenu] = useState(false);
  const { currentChapterIndex, setCurrentChapterIndex, currentUserDetails } = React.useContext(AppContext);
  const videScrollID = [FEATURED_SCROLLID, VIDEO_SCROLLID, FOOTER_VIDEO_SCROLLID];
  const [currentVideoID, setCurrentVideoID] = useState(1);
  const [showFooterNav, setShowFooterNav] = useState(false);
  const [completeBookForUser, completeBookResponse, error, loading] = useFetch('POST');

  const relatedEpisodesWithDetails = relatedEpisodes
    ?.filter((content) => content?.relatedEpisode?.uid)
    .map((item) => {
      const watch = data.totalWatchData.nodes.find((watch) => watch.uid === item.relatedEpisode.uid);
      return {
        ...watch.data,
        category: 'WATCH',
        uid: item.relatedEpisode.uid,
        title: watch.data.title[0].text,
        duration: watch.data.videoDuration[0].text,
        imageUrl: watch.data.thumbnailImage.url,
      };
    });

  const relatedReadsWithDetails = relatedReads
    ?.filter((content) => content?.relatedRead?.uid)
    .map((item) => {
      const read = data.readData.nodes.find((read) => read.uid === item?.relatedRead?.uid);
      return {
        ...read.data,
        category: 'ARTICLE',
        url: read.url,
        title: read.data.title.text,
        duration: read.data.readDuration.text,
        imageUrl: read.data.featuredImage.url,
      };
    });

  const relatedListensWithDetails = relatedListens
    ?.filter((content) => content?.relatedListen?.uid)
    .map((item) => {
      const podcast = data.podCastData.nodes.find((podcast) => podcast.uid === item.relatedListen.uid);
      return {
        ...podcast.data,
        category: 'LISTEN',
        uid: item.relatedListen.uid,
        title: podcast.data.podCastTitle[0].text,
        imageUrl: podcast.data.thumnailImage.url,
        duration: podcast.podcastDuration,
      };
    });

  const relatedReadCount = relatedReadsWithDetails.length;
  const relatedEpisodesCount = relatedEpisodesWithDetails.length;
  const relatedListenCount = relatedListensWithDetails.length;

  let relatedData = [];

  if (relatedEpisodes.length) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedListenCount, relatedData, 3);
    relatedData = relatedData.concat(relatedEpisodesWithDetails.slice(0, sliceNumber));
  }
  if (relatedReadCount) {
    const sliceNumber = getSliceNumber(relatedEpisodesCount, relatedListenCount, relatedData, 3);
    relatedData = relatedData.concat(relatedReadsWithDetails.slice(0, sliceNumber));
  }

  if (relatedListenCount) {
    const sliceNumber = getSliceNumber(relatedReadCount, relatedEpisodesCount, relatedData, 3);
    relatedData = relatedData.concat(relatedListensWithDetails.slice(0, sliceNumber));
  }

  const handleNextPrev = (type) => {
    if (currentChapterIndex === 0 && type === 'prev') return;
    if (currentChapterIndex === allChapters?.length - 1 && type === 'next') return;

    if (type === 'next') {
      if (currentChapterIndex === allChapters?.length - 1) {
        setCurrentChapterIndex(0);
        navigate(`/books/${allChapters[0]?.uid}`);
      } else {
        setCurrentChapterIndex(currentChapterIndex + 1);
        navigate(`/books/${allChapters[currentChapterIndex + 1]?.uid}`);
      }

      return;
    }
    if (currentChapterIndex === 0) {
      setCurrentChapterIndex(allChapters?.length - 1);
      navigate(`/books/${allChapters[allChapters?.length - 1]?.uid}`);
    } else {
      setCurrentChapterIndex(currentChapterIndex - 1);
      navigate(`/books/${allChapters[currentChapterIndex - 1]?.uid}`);
    }
  };
  useEffect(() => {
    const chapterIndex = allChapters.findIndex((chapter) => chapter.uid === uid);
    setCurrentChapterIndex(chapterIndex);
  }, []);

  useEffect(() => {
    if (currentUserDetails?.customer?.id && data?.allPrismicBookChapter?.nodes?.length) {
      completeBookForUser('api/postCompletedBook', {
        body: {
          bookId: data?.allPrismicBookChapter?.nodes[0].uid,
          userId: currentUserDetails?.customer?.id,
        },
      });
    }
  }, []);

  const [contentMenuRightPosition, setContentMenuRightPosition] = useState(0);
  let divElement, windowWidth, gcContainerWidth;
  useEffect(() => {
    if (typeof window != 'undefined') {
      divElement = document.getElementById(videScrollID[currentVideoID]);
      windowWidth = window.innerWidth;
      gcContainerWidth = document.getElementById('gcContainerID')?.offsetWidth - 40;
      setContentMenuRightPosition(windowWidth - gcContainerWidth);
    }
  }, []);
  if (typeof window != 'undefined') {
    divElement = document.getElementById(videScrollID[currentVideoID]);
  }
  const scroll = () => {
    divElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  };

  //share via native share

  let resultPara, shareUrl;
  if (typeof window !== 'undefined') {
    resultPara = document.querySelector('.result');
    shareUrl = window.location && window?.location?.href;
  }
  const shareData = {
    title: 'Share',
    text: '',
    url: '',
  };

  const handleShareNative = async () => {
    shareData.url = shareUrl;
    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error(err);
    }
  };

  const relatedContentIcons = {
    WATCH: {
      icon: <WatchIcon />,
      todo: 'WATCH',
      url: '/watch',
    },
    ARTICLE: {
      icon: <ReadIcongc />,
      todo: 'READ',
      url: '/read',
    },
    LISTEN: {
      icon: <ListengcIcon />,
      todo: 'LISTEN',
      url: '/podcast',
    },
  };

  var seoData = {
    canonical: sliceData?.uri,
    title: (chapterTitle?.text || '') + ' - The Great Controversy',
    metaDesc: shortDescription?.text || `Read more about The Great Controversy -${chapterTitle?.text || ''}`,
    opengraphDescription:
      shortDescription?.text || `Read more about The Great Controversy -${chapterTitle?.text || ''}`,
    twitterDescription: shortDescription?.text || `Read more about The Great Controversy -${chapterTitle?.text || ''}`,
    opengraphTitle: chapterTitle?.text || '',
    twitterTitle: chapterTitle?.text || '',
    opengraphImage: {
      sourceUrl: featuredVideoThumbnailImageUrl?.fluid?.src,
    },
    twitterImage: {
      sourceUrl: featuredVideoThumbnailImageUrl?.fluid?.src,
    },
    opengraphUrl: sliceData?.uri || '',
  };

  return (
    <BodyClassName className="  page-read page-read--single body-dark page-watch">
      <DefaultLayoutComponent backUrl="/books/the-great-controversy" timeline={true} gcbooks={true} seo={seoData}>
        <div className="bookChapterPageContainer">
          <div className={showReadModal ? 'readChapterModal' : 'hide'}>
            <div className="gcmodalCloseButton" onClick={() => setShowReadModal(false)}>
              <CloseIcongc />
            </div>
            <h3>
              Chapter {currentChapterIndex + 1} - {chapterTitle?.text}
            </h3>
            <div className="textContentGC"> {renderHtml(decode(chapterText))}</div>
          </div>
          <Backdrop
            BackdropStatus={showReadModal ? true : false}
            onBackDropClick={setShowReadModal}
            backdropPayload={false}
          />
          <div
            className="wrapper wrapper-xl pd-x-md displayflexContentMenu"
            id="gcContainerID"
            style={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <div className="gcBooksChapterChain" id={`${contractContent ? '' : 'gcBooksChapterChainContract'}`}>
              <div
                className={`${contractContent ? 'gcBooksCollapseButton' : 'gcBooksExpandButton'}`}
                onClick={() => setContractContent(!contractContent)}
              >
                <CollapseButton />
              </div>

              <div className={`${contractContent ? 'chapterChainHeader' : 'chapterChainHeader flex-c'}`}>
                <h3>{contractContent ? 'chapters' : 'CH'}</h3>
                {contractContent ? (
                  <h5>
                    Read <span className="textYellow">{currentChapterIndex + 1}</span> of {allChapters?.length}
                  </h5>
                ) : (
                  <h5>
                    <span className="textYellow">{currentChapterIndex + 1}</span> /{allChapters?.length}
                  </h5>
                )}
              </div>
              <div className="gcBooksChaptersContainer ">
                {allChapters?.map((chapter, idx) => {
                  const title = chapter?.data?.chapterTitle?.text;
                  return (
                    <Link to={`/books/${chapter?.uid}`}>
                      <div className="chapterAndChain">
                        <i className="chapterChainIcon">
                          <ChainDisabled
                            color={idx <= currentChapterIndex ? 'selected' : 'disabled'}
                            fill={idx < currentChapterIndex ? 'selected' : 'disabled'}
                          />
                        </i>

                        <Link to={`/books/${chapter?.uid}`} className={`${contractContent ? '' : 'slide'}`}>
                          <h5
                            className={
                              idx < currentChapterIndex
                                ? 'disabledText'
                                : idx === currentChapterIndex
                                ? 'textYellow'
                                : ''
                            }
                            onClick={() => setCurrentChapterIndex(idx)}
                          >
                            Ch. {idx + 1} - {title}
                          </h5>
                        </Link>
                        {idx === allChapters?.length - 1 ? (
                          ''
                        ) : (
                          <div className={`${idx < currentChapterIndex ? 'gcChainLineYellow' : 'gcChainLine'}`}></div>
                        )}
                      </div>{' '}
                    </Link>
                  );
                })}
              </div>
            </div>
            <div
              className={`${
                contractContent
                  ? 'bookChapterContentWrapper  contractContent '
                  : 'bookChapterContentWrapper expandContent'
              }`}
            >
              <div className="chapterPageHeader ">
                <h3>
                  Chapter {currentChapterIndex + 1} - {chapterTitle?.text}
                </h3>
                <div className=" chapterPageMenuResp menuItemsChapterPage">
                  <Link to={`/timeline-details/event/${timelineLink?.uid}`}>
                    <div className="menuItemChapterPage">
                      <WatchIcon color={COLOR_GRAY} /> <h5>View timeline</h5>
                    </div>
                  </Link>
                  {chapterText ? (
                    <div className="menuItemChapterPage cursor-pointer" onClick={() => setShowReadModal(true)}>
                      <BookIconWhite color={COLOR_GRAY} />
                      <h5>Read Chapter</h5>
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="menuItemChapterPage margin32 cursor-pointer" onClick={() => handleShareNative()}>
                    <ShareIconWhite color={COLOR_GRAY} />
                  </div>
                </div>
              </div>
              <ReadPageContent
                body={sliceData}
                from="gc"
                discussionQuestions={discussionQuestions}
                featuredVideo={featuredVideo}
                footerVideo={footerVideo}
                featuredVideoDuration={featuredVideoDuration}
                footerVideoThumbnailImage={footerVideoThumbnailImageUrl}
                featuredVideoThumbnailImage={featuredVideoThumbnailImageUrl}
                chapterTitle={chapterTitle}
                additionalVideos={additionalVideos}
              />
              <div className="read-footer bg-transparent">
                <div className="wrapper wrapper-xl bg-white" id="pd-x-mdgc">
                  <div className="section r_s_footer-info pd-t-0 pd-x-sm">
                    <div className="wrapper wrapper-md pd-x-sm">
                      <div className="wrapper-sm">
                        <SocialShare title={title} />
                        {!isListEmpty(furtherReadingList) && (
                          <div className="r_s_author">
                            <h6 className="r_s_author-name">Further Reading</h6>
                            {furtherReadings.map((fReading) => (
                              <p className="r_s_author-desc">{fReading?.furtherReading?.text}</p>
                            ))}
                          </div>
                        )}
                        {/* {!isListEmpty(furtherListeningList) && (
                          <div className="r_s_author">
                            <h6 className="r_s_author-name">Further Listening</h6>
                            {furtherListening.map((fListening) => (
                              <p className="r_s_author-desc">{fListening?.listening?.text}</p>
                            ))}
                          </div>
                        )} */}
                        {!isListEmpty(footNotesList) && (
                          <div className="r_s_author">
                            <h6 className="r_s_author-name">Footnotes</h6>
                            {footnotes.map((fnote) => (
                              <p className="r_s_author-desc">{fnote?.footnote?.text}</p>
                            ))}
                          </div>
                        )}

                        {chapterText ? (
                          <div className="gcslogan" onClick={() => setShowReadModal(true)}>
                            READ CHAPTER IN {bookTitle}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wrapper wrapper-xl pd-x-md gcNextChapter">
                {/* <div className="media-gc ratio_16-16_gc"> */}
                <div className="nextChapterImg">
                  <img
                    src={
                      allChapters[currentChapterIndex === allChapters?.length - 1 ? 0 : currentChapterIndex + 1]?.data
                        ?.thumbnailImage?.fixed?.src
                    }
                  />
                </div>
                {/* </div> */}
                <div className="nextChapterDesc">
                  <h3>
                    Next: Chapter {currentChapterIndex === allChapters?.length - 1 ? 1 : currentChapterIndex + 2} -
                    {
                      allChapters[currentChapterIndex === allChapters?.length - 1 ? 0 : currentChapterIndex + 1]?.data
                        ?.chapterTitle?.text
                    }
                  </h3>
                  <p>
                    {
                      allChapters[currentChapterIndex === allChapters?.length - 1 ? 0 : currentChapterIndex + 1]?.data
                        ?.shortDescription?.text
                    }
                  </p>
                </div>
                <Link
                  to={`/books/${
                    allChapters[currentChapterIndex === allChapters?.length - 1 ? 0 : currentChapterIndex + 1]?.uid
                  }`}
                >
                  <div className="nextChapterBTN">
                    <h5>Next</h5>

                    <NextArrow />
                  </div>
                </Link>
              </div>
              <div className="gcBooksRelatedContents">
                <h4>Related contents</h4>
                <div className="gcBooksRelatedContentItems">
                  {/* <RelatedContent relatedContent={relatedData} /> */}
                  {relatedData?.map((item) => {
                    const title = item?.title;
                    const imageUrl = item?.imageUrl;
                    const uid = item?.uid;
                    const durations = item?.duration;
                    return (
                      <div className="gcBooksRelatedContentItem">
                        {item?.category && (
                          <Link to={relatedContentIcons[item?.category]?.url}>
                            <div className="category-tag-gc">
                              {relatedContentIcons[item?.category]?.icon}
                              <h6>{relatedContentIcons[item?.category]?.todo}</h6>
                            </div>
                          </Link>
                        )}
                        <Link to={`/read/${uid}`}>
                          <div className="gcBooksRelatedContentphoto">
                            <img src={imageUrl} alt={title} />
                            {durations && (
                              <div className="contentTime">
                                {durations} {item?.category === 'ARTICLE' ? 'MIN READ' : ''}
                              </div>
                            )}
                          </div>
                        </Link>
                        <div className="gcBooksRelatedContentTitle">
                          <h5>{title}</h5>
                          <p>{item.category}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="chapterContentMenu" style={{ right: `${contentMenuRightPosition / 2}px` }}>
              <div className="chapterPageSharemenu">
                <div className="chapterPagePrevNext">
                  <div
                    className="prevNextButton "
                    onClick={() => {
                      handleNextPrev('prev');
                    }}
                  >
                    <PrevIconWhite active={currentChapterIndex === 0 ? false : true} /> <h6>Prev.</h6>
                  </div>
                  {/* <Link to={`/books/${allChapters[currentChapterIndex + 1]?.uid}`}> */}
                  <div
                    className="prevNextButton "
                    onClick={() => {
                      handleNextPrev('next');
                    }}
                  >
                    <h6>Next.</h6>
                    <NextIconWhite active={currentChapterIndex === allChapters?.length - 1 ? false : true} />
                  </div>
                  {/* </Link> */}
                </div>

                <div className="menuItemsChapterPage">
                  {timelineLink?.uid && (
                    <Link to={`/timeline-details/event/${timelineLink?.uid}`} className="margin-b-32">
                      <div className="menuItemChapterPage">
                        <WatchIcon /> <h6>View timeline</h6>
                      </div>
                    </Link>
                  )}
                  <div className="menuItemChapterPage margin-b-32" onClick={() => setShowShareMenu(true)}>
                    <ShareIconWhite /> <h6>Share Chapter</h6>
                    {showShareMenu ? <ShareMenu /> : ''}
                  </div>
                  <Backdrop
                    BackdropStatus={showShareMenu ? true : false}
                    onBackDropClick={setShowShareMenu}
                    backdropPayload={false}
                  />
                  {chapterText ? (
                    <div className="menuItemChapterPage" onClick={() => setShowReadModal(true)}>
                      <BookIconWhite />
                      <h6>Read Chapter</h6>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div
                className="next-video-btn"
                onClick={() => {
                  scroll();
                  setCurrentVideoID(currentVideoID === 2 ? 0 : currentVideoID + 1);
                }}
              >
                <PlayIcongc />
                <h6> Next Video</h6>
              </div>

              <div className="gc-link-container">
                <a href="https://www.freegc.org/" target="_blank" className="gc-link">
                  <div className="arrow-image-container">
                    <img src={GCBook} />
                    <div>
                      <img src={UpLeftArrow} />
                    </div>
                  </div>

                  <p className="gc-link-text link-header">Get Free Copy</p>

                  <p className="gc-link-text link-sub">Full books are available as a physical copy.</p>
                </a>
              </div>
            </div>
          </div>
          <div className=" gcFooterMenu">
            {/* <div className="gcBooksChapterChainFooter " id="gcBooksChapterChainContract"> */}
            <div className="icon-footer-watch">
              <i className="res-watch-icon">
                <WatchIcon />
              </i>

              <div
                className="chapterChainHeader flex-c cursor-pointer marginl-10 res-m0"
                onClick={() => setShowFooterNav(!showFooterNav)}
              >
                <h3>chapters</h3>

                {contractContent ? (
                  <h5>
                    Read <span className="textYellow">{currentChapterIndex + 1}</span> of {allChapters?.length}
                  </h5>
                ) : (
                  <h5>
                    <span className="textYellow">{currentChapterIndex + 1}</span> /{allChapters?.length}
                  </h5>
                )}
              </div>
            </div>
            {/* </div> */}
            <div className="chapterPagePrevNext">
              <div
                className="prevNextButton "
                onClick={() => {
                  handleNextPrev('prev');
                }}
              >
                <PrevIconWhite active={currentChapterIndex === 0 ? false : true} /> <h6>Prev.</h6>
              </div>
              {/* <Link to={`/books/${allChapters[currentChapterIndex + 1]?.uid}`}> */}
              <div
                className="prevNextButton "
                onClick={() => {
                  handleNextPrev('next');
                }}
              >
                <h6>Next.</h6>
                <NextIconWhite active={currentChapterIndex === allChapters?.length - 1 ? false : true} />
              </div>
              {/* </Link> */}
            </div>
            {/* menu */}
            <div className={showFooterNav ? 'footerMenuOuter' : 'hide'}>
              <div className="minimize-menu" onClick={() => setShowFooterNav(false)}>
                <CloseIcongc color={COLOR_GRAY} />
              </div>
              <div
                className="gcBooksChapterChainMenuFooter"
                id={`${contractContent ? '' : 'gcBooksChapterChainContract'}`}
              >
                {/* <div
                className={`${contractContent ? 'gcBooksCollapseButton' : 'gcBooksExpandButton'}`}
                onClick={() => setContractContent(!contractContent)}
              >
                <CollapseButton />
              </div> */}
                <div className="chapterChainHeader ">
                  <h3>chapters </h3>
                  {contractContent ? (
                    <h5>
                      Read <span className="textYellow">{currentChapterIndex + 1}</span> of {allChapters?.length}
                    </h5>
                  ) : (
                    <h5>
                      <span className="textYellow">{currentChapterIndex + 1}</span> /{allChapters?.length}
                    </h5>
                  )}
                </div>
                <div className="gcBooksChaptersContainer  height-menu">
                  {allChapters?.map((chapter, idx) => {
                    const title = chapter?.data?.chapterTitle?.text;
                    return (
                      <div className="chapterAndChain">
                        <i className="chapterChainIcon">
                          <ChainDisabled
                            color={idx <= currentChapterIndex ? 'selected' : 'disabled'}
                            fill={idx < currentChapterIndex ? 'selected' : 'disabled'}
                          />
                        </i>

                        <Link to={`/books/${chapter?.uid}`} className={`${contractContent ? '' : 'slide'}`}>
                          <h5
                            className={
                              idx < currentChapterIndex
                                ? 'disabledText'
                                : idx === currentChapterIndex
                                ? 'textYellow'
                                : ''
                            }
                            onClick={() => setCurrentChapterIndex(idx)}
                          >
                            Ch. {idx + 1} - {title}
                          </h5>
                        </Link>
                        {idx === allChapters?.length - 1 ? (
                          ''
                        ) : (
                          <div className={`${idx < currentChapterIndex ? 'gcChainLineYellow' : 'gcChainLine'}`}></div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
}

export const query = graphql`
  query SingleChapterPageQuery($uid: String!) {
    chapters: allPrismicBookChapter(sort: { fields: data___display_priority, order: ASC }) {
      nodes {
        data {
          thumbnailImage: thumbnail_image {
            fluid {
              src
            }
            fixed {
              src
            }
            url
          }

          chapterOfBook: chapter_of_book {
            document {
              ... on PrismicBooks {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
          chapterTitle: chapter_title {
            text
          }
          shortDescription: short_description {
            text
          }
        }
        uid
      }
    }
    allPrismicBookChapter(filter: { uid: { eq: $uid } }) {
      nodes {
        uid
        data {
          relatedReads: related_reads {
            relatedRead: related_read {
              id
              uid
            }
          }
          additionalVideos: additional_video {
            thumbnail {
              alt
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            title {
              text
            }
            duration {
              text
            }
            youtubeId: youtube_id {
              text
            }
          }

          relatedEpisodes: related_episodes {
            relatedEpisode: related_episode {
              id
              uid
            }
          }
          relatedListens: related_listens {
            relatedListen: related_listen {
              id
              uid
            }
          }
          featuredVideoThumbnailImage: featured_video_thumbnail_image {
            fluid {
              src
            }
          }
          footerVideoThumbnailImage: footer_video_thumbnail_image {
            fluid {
              src
            }
          }
          featuredVideoDuration: featured_video_duration {
            text
          }
          bookText: book_text {
            document {
              ... on PrismicChapterText {
                id
                data {
                  chapterText: chapter_text {
                    text
                  }
                }
              }
            }
          }
          displayPriority: display_priority
          timelineLink: timeline_link {
            uid
          }
          body {
            ... on PrismicBookChapterBodyText {
              id
              sliceType: slice_type
              primary {
                backgroundColor: background_color
                sectionContents: section_contents {
                  html
                }
              }
            }
            ... on PrismicBookChapterBodyPhoto {
              id
              items {
                image {
                  alt
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              sliceType: slice_type
              primary {
                backgroundColor: background_color
              }
            }
            ... on PrismicBookChapterBodyQuote {
              id
              primary {
                backgroundColor: background_color
                sectionContents: section_contents {
                  text
                }
              }
              sliceType: slice_type
            }
            ... on PrismicBookChapterBodyVideo {
              id
              primary {
                sliceVideoThumbnail: slice_video_thumbnail {
                  fluid {
                    src
                  }
                }
                backgroundColor: background_color
                videoId: video_id {
                  text
                }
              }
              sliceType: slice_type
            }
          }
          chapterTitle: chapter_title {
            text
          }
          shortDescription: short_description {
            text
          }
          featuredVideo: feature_video_id {
            text
          }
          furtherReadings: further_readings {
            furtherReading: further_reading {
              text
            }
          }

          footnotes {
            footnote {
              text
            }
          }
          footerVideo: footer_video_id {
            text
          }
          discussionQuestions: discussion_questions {
            discussionQuestion: discussion_question {
              text
            }
          }
        }
      }
    }
    totalWatchData: allPrismicEpisodes(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          title {
            text
          }
          videoDuration: video_duration {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
          youtubeId: youtube_id {
            text
          }
        }
        uid
      }
    }
    podCastData: allPrismicPodcast(sort: { fields: data___priority, order: ASC }) {
      nodes {
        data {
          thumnailImage: thumbnail_image {
            url
          }
          podCastTitle: podcast_title {
            text
          }
          season {
            type
            uid
          }
          podcastDuration: podcast_duration
          priority
        }
        uid
      }
    }
    readData: allPrismicRead(sort: { fields: data___priority }) {
      nodes {
        data {
          title {
            text
          }
          readDuration: read_duration1 {
            text
          }
          featuredImage: featured_image {
            alt
            url
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        url
        uid
      }
    }
  }
`;
