import React from 'react';

export default function PrevIconWhite({ active }) {
  return (
    <svg width={12} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 21 1 11 11 1"
        stroke={active ? '#FECB04' : '#fff'}
        a
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
