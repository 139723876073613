import React from 'react';

export default function BookIconWhite({ color }) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 7h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H6V7ZM26 7h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7V7Z"
        stroke={color ? color : '#fff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
