import React from 'react';

export default function ShareIconWhite({ color }) {
  return (
    <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="" stroke={color ? color : '#fff'} strokeWidth={1.455} strokeLinecap="round" strokeLinejoin="round">
        <path d="M10.182 18.908a2.91 2.91 0 1 0 0-5.818 2.91 2.91 0 0 0 0 5.818ZM20.364 25.455a2.91 2.91 0 1 0 0-5.818 2.91 2.91 0 0 0 0 5.818ZM20.364 12.363a2.91 2.91 0 1 0 0-5.818 2.91 2.91 0 0 0 0 5.818ZM17.918 11.028l-5.291 3.4M12.627 17.573l5.29 3.4" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(4.363 4.364)" d="M0 0h23.273v23.273H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
