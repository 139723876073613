import React from 'react';

export default function ChainDisabled({ color, fill }) {
  return (
    <svg width={15} height={14} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={7.4}
        cy={7}
        r={6}
        fill={fill === 'selected' ? '#fecb04' : '#0A0A0A'}
        stroke={color === 'selected' ? '#fecb04' : '#3B3B3B'}
        strokeWidth={2}
      />
    </svg>
  );
}
