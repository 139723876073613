import React from 'react';

export default function NextIconWhite({ active }) {
  return (
    <svg width={12} height={22} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m1 21 10-10L1 1"
        stroke={active ? '#FECB04' : '#fff'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
