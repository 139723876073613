import React from 'react';

export default function NextArrow() {
  return (
    <svg width={40} height={11} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 5.5h38" stroke="#0A0A0A" strokeLinecap="round" />
      <path
        d="M39.592 5.13a.5.5 0 0 1 0 .74l-4.006 3.635a.5.5 0 0 1-.836-.37v-7.27a.5.5 0 0 1 .836-.37l4.006 3.635Z"
        fill="#0A0A0A"
      />
    </svg>
  );
}
