import React from 'react';

export default function ListengcIcon() {
  return (
    <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          d="M17.614 10.625h-2.5a1.25 1.25 0 0 0-1.25 1.25V15a1.25 1.25 0 0 0 1.25 1.25h1.25a1.25 1.25 0 0 0 1.25-1.25v-4.375Zm0 0a7.5 7.5 0 0 0-7.557-7.5 7.5 7.5 0 0 0-7.557 7.5V15a1.25 1.25 0 0 0 1.25 1.25H5A1.25 1.25 0 0 0 6.25 15v-3.125A1.25 1.25 0 0 0 5 10.625H2.5"
          stroke="#F4F5F7"
          strokeWidth={1.206}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
