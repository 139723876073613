import React from 'react';

export default function CollapseButton() {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={40} height={40} rx={20} transform="matrix(-1 0 0 1 40 0)" fill="#0A0A0A" />
      <path d="m21 12-8 8 8 8" stroke="#ADADAD" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path d="m28 12-8 8 8 8" stroke="#ADADAD" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}
